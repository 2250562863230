var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-list',{attrs:{"disableXScroll":false,"heading":_vm.heading,"headings":[
    _vm.$t('fields.date'),
    _vm.$tc('models.group', 1),
    _vm.$t('fields.available'),
    _vm.$t('language.language'),
    _vm.$t('fields.location'),
    _vm.$t('fields.actions'),
  ],"sort":[
    { prop: 'date', type: false },
    undefined,
    { prop: 'participants', type: false },
    undefined,
    { prop: 'location', type: false },
    
  ],"items":_vm.sessions,"search_selectors":['date']},scopedSlots:_vm._u([{key:"default",fn:function(props){return [[(
          _vm.$d(new Date(props.item.startDateTime), 'fullShort') === _vm.$d(new Date(props.item.endDateTime), 'fullShort')
        )?_c('ui-list-data',{staticClass:"list_data",attrs:{"text":`
                ${_vm.$d(new Date(props.item.startDateTime), 'fullShort', _vm.$i18n.locale === 'no' ? 'no' : 'en')}
            `}}):_vm._e(),(
          _vm.$d(new Date(props.item.startDateTime), 'fullShort') != _vm.$d(new Date(props.item.endDateTime), 'fullShort')
        )?_c('ui-list-data',{staticClass:"list_data",attrs:{"text":`
                ${_vm.$d(new Date(props.item.startDateTime), 'fullShort', _vm.$i18n.locale === 'no' ? 'no' : 'en')} 
                - 
                ${_vm.$d(new Date(props.item.endDateTime), 'fullShort', _vm.$i18n.locale === 'no' ? 'no' : 'en')}
            `}}):_vm._e()],_c('ui-list-data',{staticClass:"list_data",attrs:{"text":`
            ${_vm.$d(new Date(props.item.startDateTime), 'time', _vm.$i18n.locale === 'no' ? 'no' : 'en')}
            -
            ${_vm.$d(new Date(props.item.endDateTime), 'time', _vm.$i18n.locale === 'no' ? 'no' : 'en')}`}}),(_vm.sessions)?[(typeof _vm.getPercentage(props.item) === 'number')?_c('ui-list-data',{staticClass:"list_data",attrs:{"text":`${Math.max(0, (props.item.progress.assignedCount - props.item.progress.completedCount))}`}}):_c('ui-list-data',{staticClass:"list_data",attrs:{"text":_vm.$t('labels.course.no_users_assigned')}})]:_vm._e(),_c('ui-list-data',{staticClass:"list_data",attrs:{"type":"tag","text":props.item.language === 'en' ? _vm.$t('language.english', 'en').toString() : _vm.$t('language.norwegian', 'no').toString()}}),_c('ui-list-data',{staticClass:"list_data",attrs:{"type":"tag","text":props.item.location}}),_c('ui-list-data',{staticClass:"list_data"},[_c('ui-link',{attrs:{"disabled":!_vm.isSessionButtonEnabled(props.item),"type":_vm.selectedSession ? (props.item.id === _vm.selectedSession.id ? 'success' : 'default') : 'default'},on:{"click":function($event){return _vm.handleSelectedSession(props.item)}}},[_vm._v(" "+_vm._s(_vm.getButtonText(props.item))+" ")])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }