<template>
  <div class="relative">
    <a
        href="#"
        ref="button"
        class="dropdown-button"
        @click.prevent="toggleDropdown"
    >
      <div>
        <img v-if="heading === 'Språk' || heading === 'Language'"
            :src="require('@/assets/images/' + getActiveLanguageIcon())"
            alt="flag"
            class="language_flag_icon"
        />
        <span class="">{{ heading }}</span>
      </div>

      <svg
          v-show="!showDropdown"
          class="status-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
      >
        <path
            d="M13.418 7.859a.695.695 0 0 1 .978 0 .68.68 0 0 1 0 .969l-3.908 3.83a.697.697 0 0 1-.979 0l-3.908-3.83a.68.68 0 0 1 0-.969.695.695 0 0 1 .978 0L10 11l3.418-3.141z"
        ></path>
      </svg>

      <svg
          v-show="showDropdown"
          class="status-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
      >
        <path
            d="M6.582 12.141a.695.695 0 0 1-.978 0 .68.68 0 0 1 0-.969l3.908-3.83a.697.697 0 0 1 .979 0l3.908 3.83a.68.68 0 0 1 0 .969.697.697 0 0 1-.979 0L10 9l-3.418 3.141z"
        ></path>
      </svg>
    </a>


    <transition name="slide">
      <div v-show="showDropdown" class="dropdown z-10">
        <div class="dropdown-bg">
          <a
              v-for="(item, index) in items"
              :key="index"
              class="dropdown-item"
              href="#"
              @click.prevent="handleClick(item)"
          >

            <img
                :src="require('@/assets/images/' + getMenuItemIcon(item))"
                alt="flag"
                class="language_flag_icon"
            />

            <span>{{ item.label }}</span>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {translate} from "@/i18n/Translator";

export default {

  props: {
    heading: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    useColoredFlags: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      showDropdown: false,

      /** @type {string} Name of the file for the grayscale Norwegian flag */
      flagNorwayGrayscale: "norwegian_flag.png",

      /** @type {string} Name of the file for the colored Norwegian flag */
      flagNorwayColored: "norwegian_flag_colored.png",

      /** @type {string} Name of the file for the grayscale UK flag */
      flagUKGrayscale: "english_flag.png",

      /** @type {string} Name of the file for the colored UK flag */
      flagUKColored: "english_flag_colored.png",
    };
  },


  methods: {
    toggleDropdown() {
      if (this.showDropdown) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    },

    openDropdown() {
      this.showDropdown = true;
      if (window.innerWidth >= 1024) {
        window.addEventListener('click', this.handleMouse);
      }
    },

    closeDropdown() {
      this.showDropdown = false;
      if (window.innerWidth >= 1024) {
        window.removeEventListener('click', this.handleMouse);
      }
    },

    handleClick(item) {
      this.closeDropdown();
      if (item.method) {
        item.method();
      }
    },

    handleMouse(e) {
      if (window.innerWidth >= 1024) {
        if (!this.$refs.button.contains(e.target)) {
          this.closeDropdown();
        }
      }
    },

    getMenuItemIcon(item) {
      if (item.label === translate(["language", "norwegian"], this.$i18n.locale)) {
        return this.useColoredFlags ? this.flagNorwayColored : this.flagNorwayGrayscale;
      } else if (item.label === translate(["language", "english"], this.$i18n.locale)) {
        return this.useColoredFlags ? this.flagUKColored : this.flagUKGrayscale;
      } else if (item.label === translate(["labels", "profile", "my_profile"], this.$i18n.locale)) {
        return "profile-user.png"
      }
      else if (item.label === translate(["auth", "logout"], this.$i18n.locale)) {
        return "logout.png"
      }
    },

    getActiveLanguageIcon() {
      if (this.$i18n.locale === "no") {
        return this.useColoredFlags ? this.flagNorwayColored : this.flagNorwayGrayscale;
      } else {
        return this.useColoredFlags ? this.flagUKColored : this.flagUKGrayscale;
      }
    }
  },
  mounted() {
    this.getActiveLanguageIcon()
  }
};
</script>

<style lang="scss" scoped>
.dropdown-button {
  @apply flex items-center justify-between font-semibold border-gray-200 border-b;
  padding-left: 1em;
  padding-right: 1em;
  height: 100%;

  &:hover {
    @apply bg-gray-100;
  }

  width: 100%;
}

.dropdown {
  @screen lg {
    @apply absolute right-0 pt-1;
  }
}

.dropdown-bg {
  @screen lg {
    @apply bg-white shadow rounded overflow-hidden w-48;
  }
  margin-top: 3em;
}

@media only screen and (max-width: 1023px) {
  .dropdown-bg {
    margin-top: 0 !important;
  }
}

.dropdown-item {
  @apply block font-semibold text-gray-600 bg-gray-100 px-4 py-4 border-gray-200 border-b;
  color: black;

  &:hover {
    @apply bg-gray-200;
  }

  @screen sm {
    @apply px-6;
  }

  @screen md {
    @apply px-8;
  }

  @screen lg {
    @apply p-4 text-sm bg-white;

    &:last-child {
      @apply border-b-0;
    }
  }
}

.status-icon {
  @apply w-6 h-6 block text-gray-500 fill-current;

  @screen lg {
    @apply w-4 h-4 ml-1;
  }
}

.slide-enter-active,
.slide-leave-active {
  @apply overflow-hidden;
  transition: all 0.3s;
  max-height: 100vh;

  @screen lg {
    max-height: auto;
    transform: translateY(0);
  }
}

.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */
{
  max-height: 0;

  @screen lg {
    max-height: auto;
    opacity: 0;
    transform: translateY(-20%);
  }
}

.language_flag_icon {
  display: inline-flex;
  width: 1.7em;
  margin-right: 0.8em;
}
</style>
