import api from "@/services/api";

export const getCourseEvent = async (id) => {
  const result = await api.get(`/courseevent/registration/${id}`);
  return result.data;
};

export const postParticipation = async (payload) => {
  return await api.post(`/courseevent/registration`, payload);
};

export const updateParticipation = async (id, payload) => {
  return await api.put(`/courseevent/registration/${id}`, payload);
};

export const getParticipant = async (id) => {
  const result = await api.get(`/courseevent/participant/${id}`);
  return result.data;
};

export const isEmailRegisteredInGroup = async (groupId, email) => {
  const result = await api.post("/courseevent/isEmailRegistered", {
    GroupId: groupId,
    Email: email
  });
  return result.data;
}