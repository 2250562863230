export const DEFAULT_LANG = 'no';

export const SupportedLanguages = [
  {
    label: 'norwegian',
    value: 'no',
    icon: '@/assets/images/flag-no.png'
  },
  {
    label: 'english',
    value: 'en',
    icon: '@/assets/images/flag-en.png'
  }
];
